import React from 'react'
import { useNavigate } from 'react-router'
import EmptyState from '../../../../components/EmptyScreen'
import RestaurantDishesCard from '../../../../components/restaurant/RestaurantDishesCard'
import { ROUTES } from '../../../../helpers/routes-helper'
import { Dish } from '../../../../types'
import {
  SearchDishInnerGrid,
  SearchDishMainGrid,
} from '../styled/SearchDish.styled'

interface SearchDishProps {
  dishList: Dish[]
}

const SearchDish: React.FC<SearchDishProps> = ({ dishList }) => {
  const navigate = useNavigate()
  return (
    <SearchDishMainGrid>
      {dishList.length > 0 &&
        dishList.map((dish, index) => {
          return (
            <SearchDishInnerGrid key={index}>
              <div
                onClick={() =>
                  navigate(
                    `${ROUTES.RESTAURANT_DETAILS}/${dish?.restaurant}/details`,
                  )
                }
              >
                <RestaurantDishesCard
                  id={dish.dishID}
                  key={index}
                  image={'/images/restaurant-logo.jpg'}
                  title={dish?.name}
                  description={dish?.description}
                />
              </div>
            </SearchDishInnerGrid>
          )
        })}
      {dishList.length === 0 && <EmptyState containerHeight={'600px'} />}
    </SearchDishMainGrid>
  )
}

export default SearchDish
