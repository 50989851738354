import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Box, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router'
import { getLocation } from '../../../helpers/location-helper'
import {
  OnboardPage,
  getSteps,
  onboardStepTypes,
} from '../../../helpers/onboard-helper'
import { ROUTES } from '../../../helpers/routes-helper'
import { useAuthHelper } from '../../../hooks/useAuthHelper'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  restaurantOnboardRequest,
  selectLogin,
  setLocation,
  updateRestaurantOnboard,
} from '../../../store/authSlice'
import {
  fetchEaterProfile,
  selectIsLoading,
  selectMessage,
  selectOnboardPayload,
  selectPersonalizePayload,
  selectRestaurant,
  selectRole,
  selectSeverity,
  selectShouldOpen,
  setDiningFrequency,
  setOnboardPayload,
  setOpen,
  setPersonalizePayload,
  setRole,
  setSocialLinks,
} from '../../../store/onboardSlice'
import { setEater } from '../../../store/profileSlice'
import {
  Eater,
  EaterPayload,
  GeneralData,
  IStepper,
  Login,
  OnboardDiningFrequencyRefMethods,
  OnboardPersonalizeMethods,
  OnboardRequestMethods,
  OnboardRequestPayloadType,
  OnboardRestaurantMethods,
  OnboardSelectionMethods,
  OnboardSocialRefMethods,
  PersonalFavoriteMethods,
  StepsPayloadMap,
} from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { debugLog } from '../../../utils/log-helper'
import { MuiAlert, MuiBackdrop, MuiSnackbar } from '../styled/global.styled'
import OnboardChooseRole from './OnboardChooseRole'
import OnboardDiningFrequency from './OnboardDiningFrequency'
import OnboardLocateYourRestaurant from './OnboardLocateYourRestaurant'
import OnboardPersonalFavorites from './OnboardPersonalFavorites'
import OnboardPersonalize from './OnboardPersonalize'
import OnboardPreferences from './OnboardPreferences'
import OnboardRequest from './OnboardRequest'
import OnboardSocial from './OnboardSocial'
import OnboardStepper from './OnboardSteppar'
import {
  OnboardContent,
  OnboardContentContainer,
  OnboardContentWarper,
  OnboardHeaderBackButton,
  OnboardHeaderButtonWarper,
  OnboardHeaderNextButton,
  OnboardHeaderSkipButton,
  OnboardHeaderWarper,
  OnboardMainContainer,
  OnboardSidebarButtonImg,
  OnboardSidebarButtonTitle,
  OnboardSidebarContainer,
  OnboardSidebarLogoImg,
  OnboardSidebarLogoutButton,
  OnboardSidebarTitleTypography,
  OnboardSidebarWarper,
  SideTitleAndStepper,
} from './styled/OnboardContainer.styled'

const Onboard: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useAppDispatch()
  const { logoutAuthUser } = useAuthHelper()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const role = useAppSelector(selectRole)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const loginResponse = useAppSelector(selectLogin)
  const isLoading = useAppSelector(selectIsLoading)
  const restaurant = useAppSelector(selectRestaurant)
  const openServer = useAppSelector(selectShouldOpen)
  const onboardPayload = useAppSelector(selectOnboardPayload)
  const personalizePayload = useAppSelector(selectPersonalizePayload)
  const defaultSteps = getSteps(role)
  const [selectedRole, setSelectedRole] = useState(role)
  const [pageTitle, setPageTitle] = useState<string>('')
  const [steps, setSteps] = useState<IStepper[]>(defaultSteps)
  const [currentStep, setCurrentStep] = useState<IStepper>(defaultSteps[0])
  const [activeStep, setActiveStep] = useState(onboardStepTypes.CHOOSE_ROLE)
  const [isNextButtonDisable, setNextButtonDisable] = useState<boolean>(false)
  const [isLoadingRestaurants, setLoadingRestaurants] = useState<boolean>(false)
  const [personalizeData, setPersonalizeData] =
    useState<GeneralData>(personalizePayload)
  const [stepsPayloads, setStepsPayloads] =
    useState<StepsPayloadMap>(onboardPayload)
  const itemListRef = useRef<OnboardSelectionMethods>(null)
  const restaurantRef = useRef<OnboardRestaurantMethods>(null)
  const onboardSocialRef = useRef<OnboardSocialRefMethods>(null)
  const onboardingRequestRef = useRef<OnboardRequestMethods>(null)
  const personalFavoriteRef = useRef<PersonalFavoriteMethods>(null)
  const onboardPersonalizeRef = useRef<OnboardPersonalizeMethods>(null)
  const onboardDiningFrequencyRef =
    useRef<OnboardDiningFrequencyRefMethods>(null)

  useEffect(() => {
    dispatch(setOnboardPayload(stepsPayloads))
  }, [stepsPayloads, dispatch])

  useEffect(() => {
    dispatch(setPersonalizePayload(personalizeData))
  }, [personalizeData, dispatch])

  useEffect(() => {
    setSteps(getSteps(selectedRole))
    dispatch(setRole(selectedRole))
  }, [selectedRole, dispatch])

  useEffect(() => {
    setPageTitle(`Resactly | ${currentStep.title} - Onboarding`)
  }, [currentStep])

  useEffect(() => {
    if (currentStep?.path) {
      navigate(`${ROUTES.ONBOARD}/${currentStep.path}`)
    }

    if(analytics && isAnalyticsReady){
      analytics?.page('PAGE_OPENED', {
        path: ROUTES.RESTAURANTS,
        title: pageTitle,
        url: `${window.location.origin}${ROUTES.ONBOARD}/${currentStep.path}`,
        category: 'Onboard',
        name: `${currentStep.title} Viewed`,
        startTime: new Date().toISOString()
      })
    }
  }, [pageTitle, currentStep, analytics, isAnalyticsReady])

  useEffect(() => {
    return () => {
      analytics?.page('PAGE_CLOSED', {
        path: ROUTES.RESTAURANTS,
        title: pageTitle,
        url: `${window.location.origin}${ROUTES.ONBOARD}/${currentStep.path}`,
        category: 'Onboard',
        name: `${currentStep.title} Viewed`,
        endTime: new Date().toISOString()
      })
    }
  }, [currentStep, location])

  useEffect(() => {
    const localStorageData = window.localStorage.getItem('login')
    const localLoginData: Login = localStorageData
      ? JSON.parse(localStorageData)
      : {}

    if (
      loginResponse?.eaterDTO?.step === 'DONE' ||
      localLoginData.eaterDTO?.step === 'DONE'
    ) {
      void getLocation().then((location) => {
        dispatch(setLocation(location))
        navigate('/dashboard')
      })
    } else {
      if (
        (loginResponse?.type === 'EATER' &&
          loginResponse?.eaterDTO?.step !== 'NEW' &&
          loginResponse?.status !== 'NEW') ||
        (localLoginData.type === 'EATER' &&
          localLoginData.eaterDTO?.step !== 'NEW' &&
          loginResponse?.status !== 'NEW')
      ) {
        setSelectedRole('eater')

        const onboardList: Eater | undefined =
          loginResponse?.eaterDTO ?? localLoginData.eaterDTO

        const routeName = getSteps('eater').find(
          (step) => step.page === onboardList?.step,
        )

        const personalize = {
          firstName: onboardList?.firstName,
          lastName: onboardList?.lastName,
          dob: onboardList?.dob,
          phone: onboardList?.phone,
          addressLine1: onboardList?.addressLine1,
          addressLine2: onboardList?.addressLine2,
          city: onboardList?.city,
          state: onboardList?.country,
          zipcode: onboardList?.zipcode,
          country: onboardList?.country,
          incomeLevel: Number(onboardList?.incomeLevel),
          gender: onboardList?.gender,
          occupation: onboardList?.occupation,
        }

        setPersonalizeData(personalize)

        const steps = [
          {
            step: 3,
            likeKey: 'ingredientLikes',
            dislikeKey: 'ingredientDislikes',
          },
          { step: 4, likeKey: 'cuisineLikes', dislikeKey: 'cuisineDislikes' },
          {
            step: 5,
            likeKey: 'foodPreparationLiked',
            dislikeKey: 'foodPreparationDisliked',
          },
          { step: 6, likeKey: 'ambienceLiked', dislikeKey: 'ambienceDisliked' },
          { step: 7, likeKey: 'dietaryPreferences', dislikeKey: null },
          { step: 8, likeKey: 'allergies', dislikeKey: null },
          {
            step: 10,
            likeKey: 'favoriteRestaurants',
            dislikeKey: 'favoriteReviewers',
          },
        ]

        const data = steps.reduce((acc, { step, likeKey, dislikeKey }) => {
          acc[step] = {
            currentStep: step,
            nextStep: step + 1,
            ...(step !== 10 && {
              likedItems:
                onboardList && onboardList[likeKey]
                  ? onboardList[likeKey].split(',')
                  : [],
            }),
            ...(step !== 10 && {
              dislikedItems:
                dislikeKey && onboardList && onboardList[dislikeKey]
                  ? onboardList[dislikeKey].split(',')
                  : [],
            }),
            ...(step === 10 && {
              favoritesRestaurantItems:
                onboardList && onboardList[likeKey]
                  ? onboardList[likeKey].split(',')
                  : [],
            }),
            ...(step === 10 && {
              foodReviewerItems:
                dislikeKey && onboardList && onboardList[dislikeKey]
                  ? onboardList[dislikeKey].split(',')
                  : [],
            }),
          }
          return acc
        }, {})

        const words = onboardList?.diningFrequency?.split(' ')
        if (words && words.length) {
          dispatch(
            setDiningFrequency({
              number: Number(words[0]),
              frequency: words[words.length - 1].toUpperCase(),
            }),
          )
        }

        const socialLinksData = {
          facebook: onboardList?.facebook ?? '',
          instagram: onboardList?.instagram ?? '',
          tiktok: onboardList?.tiktok ?? '',
          x: onboardList?.x ?? '',
        }

        dispatch(setSocialLinks(socialLinksData))

        if (routeName) {
          setCurrentStep(routeName)
          setActiveStep(routeName.step)
          setStepsPayloads(data)
          navigate(`${ROUTES.ONBOARD}/${routeName.path}`)
        }
      } else {
        setSteps(defaultSteps)
        if (currentStep?.path) {
          navigate(`${ROUTES.ONBOARD}/${currentStep.path}`)
        }
      }
    }
  }, [])

  useEffect(() => {
    const step = steps.find((item) => item.step === activeStep)
    if (step) {
      setCurrentStep(step)
    }
  }, [activeStep, steps])

  const proceedToNextStep = async (): Promise<void> => {
    if (currentStep.step === onboardStepTypes.CHOOSE_ROLE) {
      if (currentStep?.nextStep) {
        setActiveStep(currentStep.nextStep)
      }
    } else {
      const payload: EaterPayload = {
        page: OnboardPage[currentStep.page],
      }
      switch (currentStep.step) {
        case onboardStepTypes.PERSONAL_FAVORITES:
          payload.favorites = {
            reviewers: [],
            restaurants: [],
          }
          break
        case onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION:
        case onboardStepTypes.ALLERGIES:
          payload.value = []
          break

        case onboardStepTypes.SOCIAL:
          if (onboardSocialRef.current) {
            payload.socialValue = {
              facebook: '',
              instagram: '',
              tiktok: '',
              x: '',
            }
          }
          break
        case onboardStepTypes.DINING_FREQUENCY:
          payload.diningFrequency = {
            number: 0,
            frequency: 'WEEK',
          }
          break
        default:
          payload.likes = []
          payload.dislikes = []
          break
      }

      const resultAction = await dispatch(fetchEaterProfile(payload))
      if (fetchEaterProfile.fulfilled.match(resultAction)) {
        if (currentStep?.nextStep) {
          setActiveStep(currentStep.nextStep)
        } else if (currentStep.step === onboardStepTypes.SOCIAL) {
          void getLocation().then((location) => {
            dispatch(setLocation(location))
            navigate('/')
          })
        }
      }
    }
  }

  const prevStep = (): void => {
    const newStep = currentStep?.prevStep
    if (newStep) {
      setActiveStep(newStep)
    } else {
      navigate('/')
    }
  }

  const handleFetchProfile = async (payload: EaterPayload): Promise<void> => {
    const resultAction = await dispatch(fetchEaterProfile(payload))

    if (fetchEaterProfile.fulfilled.match(resultAction)) {
      dispatch(setEater(resultAction.payload.eaterDTO))
      if (currentStep?.nextStep) {
        setActiveStep(currentStep.nextStep)
      } else if (currentStep.step === onboardStepTypes.SOCIAL) {
        void getLocation().then((location) => {
          dispatch(setLocation(location))
          navigate('/')
        })
      } else if (
        currentStep.step === onboardStepTypes.REQUEST_ONBOARDING_RESTAURANT
      ) {
        navigate(`${ROUTES.RESTAURANT_ADMIN_PENDING}`)
      }
    }
    if (fetchEaterProfile.rejected.match(resultAction)) {
      onNextStepError(payload)
    }
  }

  const onNextStepError = (payload: EaterPayload): void => {
    const localStorageData: Login = JSON.parse(
      window.localStorage.getItem('login') ?? '{}',
    )
    const { eaterDTO } = localStorageData

    const updateLocalStorage = (updatedData: Partial<Eater>): void => {
      localStorage.setItem(
        'login',
        JSON.stringify({
          ...localStorageData,
          eaterDTO: {
            ...eaterDTO,
            ...updatedData,
          },
        }),
      )
    }

    const joinPayloadValues = (values: string[] | undefined): string =>
      values?.join(',') ?? ''

    let frequency: string = ''
    let number: number = 0

    switch (currentStep.step) {
      case onboardStepTypes.PERSONALIZE:
        updateLocalStorage(payload.eaterDTO)
        break
      case onboardStepTypes.INGREDIENTS:
        updateLocalStorage({
          ingredientLikes: joinPayloadValues(payload.likes),
          ingredientDislikes: joinPayloadValues(payload.dislikes),
        })
        break
      case onboardStepTypes.CUISINES:
        updateLocalStorage({
          cuisineLikes: joinPayloadValues(payload.likes),
          cuisineDislikes: joinPayloadValues(payload.dislikes),
        })
        break
      case onboardStepTypes.FOOD_PREPARATIONS:
        updateLocalStorage({
          foodPreparationLiked: joinPayloadValues(payload.likes),
          foodPreparationDisliked: joinPayloadValues(payload.dislikes),
        })
        break
      case onboardStepTypes.DINING_AMBIENCE:
        updateLocalStorage({
          ambienceLiked: joinPayloadValues(payload.likes),
          ambienceDisliked: joinPayloadValues(payload.dislikes),
        })
        break
      case onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION:
        updateLocalStorage({
          dietaryPreferences: joinPayloadValues(payload.value),
        })
        break
      case onboardStepTypes.ALLERGIES:
        updateLocalStorage({
          allergies: joinPayloadValues(payload.value),
        })
        break
      case onboardStepTypes.DINING_FREQUENCY:
        frequency = payload?.diningFrequency?.frequency ?? ''
        number = payload?.diningFrequency?.number ?? 0
        updateLocalStorage({
          diningFrequency: `${number} times per ${frequency}`,
        })
        break
      case onboardStepTypes.PERSONAL_FAVORITES:
        updateLocalStorage({
          favoriteRestaurants: joinPayloadValues(
            payload?.favorites?.restaurants,
          ),
          favoriteReviewers: joinPayloadValues(payload?.favorites?.reviewers),
        })
        break
      case onboardStepTypes.SOCIAL:
        updateLocalStorage({
          facebook: payload?.socialValue?.facebook,
          instagram: payload?.socialValue?.instagram,
          tiktok: payload?.socialValue?.tiktok,
          x: payload?.socialValue?.x,
        })
        break
      default:
        break
    }
  }

  const handleNext = async (): Promise<void> => {
    if (
      currentStep.step !== onboardStepTypes.REQUEST_ONBOARDING_RESTAURANT &&
      currentStep.step !== onboardStepTypes.LOCATE_YOUR_RESTAURANT
    ) {
      const payload: EaterPayload = {
        page: OnboardPage[currentStep.page],
      }

      switch (currentStep.step) {
        case onboardStepTypes.PERSONALIZE:
          if (onboardPersonalizeRef.current) {
            payload.eaterDTO = await onboardPersonalizeRef.current.getPayload()
          }
          break
        case onboardStepTypes.INGREDIENTS:
        case onboardStepTypes.CUISINES:
        case onboardStepTypes.FOOD_PREPARATIONS:
        case onboardStepTypes.DINING_AMBIENCE:
        case onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION:
        case onboardStepTypes.ALLERGIES:
          if (itemListRef.current) {
            const { likedItems, dislikedItems } =
              itemListRef.current.getPayload()

            const updatedPayload = {
              currentStep: currentStep.step,
              nextStep: currentStep?.nextStep as number,
              likedItems,
              dislikedItems,
            }

            setStepsPayloads((prevPayloads) => ({
              ...prevPayloads,
              [currentStep.step]: updatedPayload,
            }))

            switch (currentStep.step) {
              case onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION:
              case onboardStepTypes.ALLERGIES:
                payload.value = likedItems
                break
              default:
                payload.likes = likedItems
                payload.dislikes = dislikedItems
                break
            }
          }
          break
        case onboardStepTypes.PERSONAL_FAVORITES:
          if (personalFavoriteRef.current) {
            const { favoritesRestaurantItems, foodReviewerItems } =
              personalFavoriteRef.current.getPayload()

            const updatedPayload = {
              currentStep: currentStep.step,
              nextStep: currentStep?.nextStep as number,
              favoritesRestaurantItems,
              foodReviewerItems,
            }

            setStepsPayloads((prevPayloads) => ({
              ...prevPayloads,
              [currentStep.step]: updatedPayload,
            }))
            payload.favorites = {
              reviewers: foodReviewerItems,
              restaurants: favoritesRestaurantItems,
            }
          }
          break
        case onboardStepTypes.SOCIAL:
          if (onboardSocialRef.current) {
            const socialValue = onboardSocialRef.current.getPayload()
            payload.socialValue = socialValue
          }
          break
        case onboardStepTypes.DINING_FREQUENCY:
          if (onboardDiningFrequencyRef.current) {
            const diningFrequency =
              onboardDiningFrequencyRef.current.getPayload()
            payload.diningFrequency = diningFrequency
          }
          break

        default:
          return await proceedToNextStep()
      }

      await handleFetchProfile(payload)
    } else {
      if (currentStep.step === onboardStepTypes.LOCATE_YOUR_RESTAURANT) {
        if (restaurantRef.current) restaurantRef.current.getPayload()
        setActiveStep(currentStep.nextStep as number)
      } else {
        if (onboardingRequestRef.current) {
          try {
            const requestPayload =
              (await onboardingRequestRef.current.getPayload()) as OnboardRequestPayloadType
            if (process.env.REACT_APP_ANALYTICS === 'true') {
              const _tfa = (window as any)._tfa
              _tfa.push({
                notify: 'event',
                name: 'signup',
                id: 1651237,
                revenue: '200',
              })

              const gtag = (window as any).gtag
              gtag('event', 'conversion', {
                send_to: 'AW-11274324379/TpS5COafr8IYEJuTgoAq',
              })
            }
            if (restaurant === undefined) {
              const onboardRequest = {
                name: requestPayload?.restaurantName ?? '',
                contactName: requestPayload?.contactName,
                contactEmail: requestPayload?.contactEmail,
                phone: requestPayload?.phoneNumber,
                website: requestPayload?.websiteLink,
              }
              setLoadingRestaurants(true)
              const resultAction = await dispatch(
                restaurantOnboardRequest(onboardRequest),
              )
              if (restaurantOnboardRequest.fulfilled.match(resultAction)) {
                setLoadingRestaurants(false)
                navigate(`/restaurant-dashboard`)
              }
              if (restaurantOnboardRequest.rejected.match(resultAction)) {
                setLoadingRestaurants(false)
              }
            } else {
              const updateRequest = {
                restaurantID: restaurant.restaurantID,
                alias: restaurant.alias,
                name: restaurant.name,
                contactName: requestPayload.contactName,
                contactEmail: requestPayload.contactEmail,
                phone: requestPayload.phoneNumber,
                website: requestPayload.websiteLink,
              }
              setLoadingRestaurants(true)
              const resultAction = await dispatch(
                updateRestaurantOnboard(updateRequest),
              )
              if (updateRestaurantOnboard.fulfilled.match(resultAction)) {
                setLoadingRestaurants(false)
                navigate(`/restaurant-dashboard`)
              }
              if (updateRestaurantOnboard.rejected.match(resultAction)) {
                setLoadingRestaurants(false)
                navigate(`/restaurant-dashboard`)
              }
            }
          } catch (error) {
            debugLog(error)
          }
        }
      }
    }
  }

  const logoutUser = (): void => {
    analytics?.identify('', { isLoggedIn: false })
    analytics?.reset()
    logoutAuthUser()
    navigate(ROUTES.HOME)
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <OnboardMainContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Get started with Resactly by setting up your dining preferences and exploring our features. Our onboarding process will guide you through customizing your experience and making the most of our AI-powered recommendations."
        />
      </Helmet>
      <MuiBackdrop
        data-testid="backdropId"
        open={isLoading || isLoadingRestaurants}
      >
        <CircularProgress color="inherit" />
      </MuiBackdrop>
      {!isSmallScreen ? (
        <OnboardSidebarContainer>
          <OnboardSidebarWarper>
            <SideTitleAndStepper>
              <OnboardSidebarLogoImg />
              <OnboardSidebarTitleTypography data-testid="sidebar-title">
                Help us serve you better
              </OnboardSidebarTitleTypography>

              <OnboardStepper activeStep={activeStep} steps={steps} />
            </SideTitleAndStepper>

            <OnboardSidebarLogoutButton onClick={logoutUser}>
              <OnboardSidebarButtonTitle>Logout</OnboardSidebarButtonTitle>
              <OnboardSidebarButtonImg />
            </OnboardSidebarLogoutButton>
          </OnboardSidebarWarper>
        </OnboardSidebarContainer>
      ) : (
        <Grid
          item
          xs={12}
          paddingX={2}
          py={2}
          style={{ borderBottom: `1px solid ${theme.palette.grey[200]}` }}
        >
          <div>
            <OnboardStepper activeStep={activeStep} steps={steps} />
          </div>
        </Grid>
      )}
      <OnboardContentContainer>
        <OnboardHeaderWarper
          position={isSmallScreen ? 'absolute' : 'relative'}
          justifyContent={
            activeStep === onboardStepTypes.CHOOSE_ROLE
              ? 'end'
              : 'space-between'
          }
        >
         <Box sx={{
          display:'flex',
          gap:'8px'
         }}>
         {activeStep !== onboardStepTypes.CHOOSE_ROLE && (
            <OnboardHeaderBackButton onClick={prevStep}>
              Back
            </OnboardHeaderBackButton>
          )}
         { isSmallScreen && <OnboardHeaderBackButton onClick={logoutUser}>
          Logout
            </OnboardHeaderBackButton>}
         </Box>
          <OnboardHeaderButtonWarper>
            {currentStep.canSkip && (
              <OnboardHeaderSkipButton
                onClick={() => {
                  void proceedToNextStep()
                }}
              >
                Skip
              </OnboardHeaderSkipButton>
            )}
            {selectedRole ? (
              <OnboardHeaderNextButton
                data-testid="next-btn"
                disabled={
                  activeStep === onboardStepTypes.CHOOSE_ROLE
                    ? !selectedRole || !selectedRole.length
                    : isNextButtonDisable
                }
                onClick={() => {
                  void handleNext()
                }}
              >
                {currentStep.nextStep === null ? 'Finish' : 'Next'}
              </OnboardHeaderNextButton>
            ) : (
              <div style={{ height: '34px' }} />
            )}
          </OnboardHeaderButtonWarper>
        </OnboardHeaderWarper>
        <OnboardContent>
          <OnboardContentWarper>
            {activeStep === onboardStepTypes.CHOOSE_ROLE && (
              <OnboardChooseRole
                validRole={selectedRole}
                setValidRole={setSelectedRole}
                title="Choose your Role"
                subTitle="Unlock a tailored experience"
                setNextButtonDisable={setNextButtonDisable}
              />
            )}
            {activeStep === onboardStepTypes.PERSONALIZE && (
              <>
                <OnboardPersonalize
                  key={onboardStepTypes.PERSONALIZE}
                  title="Personalizes your Experience"
                  subTitle="Help us tailor your restaurant recommendations by entering the following details.
                  Your information will be securely stored and used enhance your dining experience."
                  setNextButtonDisable={setNextButtonDisable}
                  ref={onboardPersonalizeRef}
                />
              </>
            )}
            {activeStep === onboardStepTypes.INGREDIENTS && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.INGREDIENTS}
                title="What Ingredients do you like?"
                subTitle="Likes"
                secondSubTitle="Dislikes"
                listName="ingredients"
                indexKey="REACT_APP_INGREDIENTS_INDEX"
                liked={
                  stepsPayloads[onboardStepTypes.INGREDIENTS]?.likedItems ?? []
                }
                dislike={
                  stepsPayloads[onboardStepTypes.INGREDIENTS]?.dislikedItems ??
                  []
                }
              />
            )}
            {activeStep === onboardStepTypes.CUISINES && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.CUISINES}
                title="What Cuisines do you like?"
                subTitle="Likes"
                secondSubTitle="Dislikes"
                listName="cuisines"
                liked={
                  stepsPayloads[onboardStepTypes.CUISINES]?.likedItems ?? []
                }
                dislike={
                  stepsPayloads[onboardStepTypes.CUISINES]?.dislikedItems ?? []
                }
                indexKey="REACT_APP_CUISINES_INDEX"
              />
            )}
            {activeStep === onboardStepTypes.FOOD_PREPARATIONS && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.FOOD_PREPARATIONS}
                title="Food Preparations"
                listName="foodPreparations"
                subTitle="Likes"
                secondSubTitle="Dislikes"
                indexKey="REACT_APP_FOOD_PREPARATION_INDEX"
                liked={
                  stepsPayloads[onboardStepTypes.FOOD_PREPARATIONS]
                    ?.likedItems ?? []
                }
                dislike={
                  stepsPayloads[onboardStepTypes.FOOD_PREPARATIONS]
                    ?.dislikedItems ?? []
                }
              />
            )}
            {activeStep === onboardStepTypes.DINING_AMBIENCE && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.DINING_AMBIENCE}
                title="What Dining Ambience do you like?"
                subTitle="Likes"
                secondSubTitle="Dislikes"
                listName="ambience"
                liked={
                  stepsPayloads[onboardStepTypes.DINING_AMBIENCE]?.likedItems ??
                  []
                }
                dislike={
                  stepsPayloads[onboardStepTypes.DINING_AMBIENCE]
                    ?.dislikedItems ?? []
                }
                indexKey="REACT_APP_AMBIENCE_INDEX"
              />
            )}
            {activeStep === onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION}
                title="Dietary Preferences and Restrictions"
                listName="dietaryPreferences"
                liked={
                  stepsPayloads[onboardStepTypes.DIETARY_PREFERENCE_RESTRICTION]
                    ?.likedItems ?? []
                }
                indexKey="REACT_APP_DIETARY_PREFERENCES_INDEX"
              />
            )}
            {activeStep === onboardStepTypes.ALLERGIES && (
              <OnboardPreferences
                ref={itemListRef}
                key={onboardStepTypes.ALLERGIES}
                title="What Allergies do you have?"
                listName="allergies"
                liked={
                  stepsPayloads[onboardStepTypes.ALLERGIES]?.likedItems ?? []
                }
                indexKey="REACT_APP_ALLERGIES_INDEX"
              />
            )}
            {activeStep === onboardStepTypes.DINING_FREQUENCY && (
              <OnboardDiningFrequency
                ref={onboardDiningFrequencyRef}
                title="Add your dining frequency"
              />
            )}
            {activeStep === onboardStepTypes.PERSONAL_FAVORITES && (
              <OnboardPersonalFavorites
                ref={personalFavoriteRef}
                key={onboardStepTypes.PERSONAL_FAVORITES}
                title="Personal Favorites"
                subTitle="What are your favorite restaurants?"
                secondSubTitle="What are your favorite reviewers?"
                firstListName="favoritesRestaurant"
                secondListName="foodReviewers"
                favoritesRestaurant={
                  stepsPayloads[onboardStepTypes.PERSONAL_FAVORITES]
                    ?.favoritesRestaurantItems ?? []
                }
                foodReviewers={
                  stepsPayloads[onboardStepTypes.PERSONAL_FAVORITES]
                    ?.foodReviewerItems ?? []
                }
                firstIndexKey="REACT_APP_RESTAURANT_INDEX"
                secondIndexKey="REACT_APP_FAVORITE_REVIEWERS_INDEX"
              />
            )}
            {activeStep === onboardStepTypes.SOCIAL && (
              <OnboardSocial
                ref={onboardSocialRef}
                title="Add your social media links"
              />
            )}
            {activeStep === onboardStepTypes.LOCATE_YOUR_RESTAURANT && (
              <OnboardLocateYourRestaurant
                ref={restaurantRef}
                title="Locate your Restaurant"
                subTitle="Select your restaurant name below to verify"
                setNextButtonDisable={setNextButtonDisable}
              />
            )}
            {activeStep === onboardStepTypes.REQUEST_ONBOARDING_RESTAURANT && (
              <OnboardRequest
                ref={onboardingRequestRef}
                title="Request Onboarding"
                subTitle="Please enter your restaurant details"
                setNextButtonDisable={setNextButtonDisable}
              />
            )}
          </OnboardContentWarper>
        </OnboardContent>
      </OnboardContentContainer>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </OnboardMainContainer>
  )
}

export default withAuthenticationRequired(Onboard)
